.parallax-pattern-image {
    max-height: 100vh;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

.parallax-pattern-image .parallax-pattern-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.parallax-pattern-image span.parallax-pattern-title {
    background-color: rgba(69, 9, 32, 0.8);
    color: #f9dbbd;
    padding: 2rem;
    font-size: 2rem;
    font-weight: 800;
}

@media (max-width: 27.5rem) {
    .parallax-pattern-image img {
        object-fit:cover;
        object-position:center;
        width: 100%;
        height: 100vh;
    }

    .parallax-pattern-image span.parallax-pattern-title {
        font-size: 1.5rem;
        text-align: center;
    }
}