.contact-box {
    text-align: center;
    position: relative;
    background-color: #450920;
    color: #f9dbbd;
    overflow-x: hidden;
}

.contact-box::before {
    content: "";
    display: block;
    height: 4.5rem;
}

.contact-box::after {
    content: "";
    display: block;
    height: 4.5rem;
}

.contact-box h2 {
    padding-bottom: 1rem;
}

.contact-box p {
    max-width: 60rem;
    font-size: 1.5rem;
    margin: 0 auto;
    padding: 1rem;
    text-align:center;
}

.contact-box ul {
    max-width: 25rem;
    font-size: 1.15rem;
    margin: 0 auto;
    text-align: center;
    list-style-type: none;
}

.contact-box,
.contact-box::before,
.contact-box::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.opening-hours-list li {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.shop-info {
    display: flex;
    justify-content: center;
    max-height: 100rem;
}

.card {
    max-width: 25rem;
    height: auto;
    border: none !important;
}

.card div {
    background-color: #450920;
    color: #f9dbbd;
}
