.founders-box {
    text-align: center;
    position: relative;
    background-color: #450920;
    color: #f9dbbd;
    overflow-x: hidden;
}

.founders-box::before {
    content: "";
    display: block;
    height: 4.5rem;
}

.founders-box::after {
    content: "";
    display: block;
    height: 4.5rem;
}

.founders-box h2 {
    padding-bottom: 1rem;
}

.founders-box p {
    max-width: 60rem;
    font-size: 1.5rem;
    margin: 0 auto;
    padding: 1rem;
    text-align:justify;
}

.founders-box ul {
    max-width: 25rem;
    font-size: 1.15rem;
    margin: 0 auto;
    text-align: center;
    list-style-type: none;
}

.founders-box,
.founders-box::before,
.founders-box::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}