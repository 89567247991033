.footer {
    padding-top: 15px;
    height: 100px;
    background-color: #121619;
    color: #495057;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -5px 10px #121619;
    overflow-x: hidden;
}

.icons {
    color: #495057;
    font-size: 2rem;
    margin-left: 1rem;
    cursor: pointer;
}

.icons:hover {
    color: #e9ecef;
    transition: linear .5s;
}

i:hover {
    content: fa-flip;
}